import { takeLatest, select, put } from 'redux-saga/effects'
import * as actions from '../../actions'
import { configurationLineItemSaga } from './configuration'
import {
  internetLineItemTypes,
  internetConfigurationItems,
  staticIpJustificationCounts,
  defaultIPSelection,
  configTypes
} from '../../constants/configuration'
import { selectLineItemByType } from '../../selectors/configuration'
import { getConfigItem, getSelectedConfigItem } from '../../utils/configuration'

const lineItemSagas = {
  [internetLineItemTypes.staticIps]: updateStaticIPs,
  [internetLineItemTypes.internetEquipment]: setInternetEquipmentSaga,
  [internetLineItemTypes.connectionPro]: updateConnectionProSaga,
  [internetLineItemTypes.securityEdge]: updateSecurityEdgeSaga,
  [internetLineItemTypes.wifiAP]: updateWifiExtendersSaga
}

export function* updateWifiExtendersSaga({ payload }) {
  const { item, lineItem } = payload
  // Select the wifi Extenders line item
  const wifiExtendersLineItem = yield select(
    selectLineItemByType(internetLineItemTypes.wifiExtenders)
  )
  const wifiExtendersItem = getConfigItem(wifiExtendersLineItem)

  if (item.id === internetConfigurationItems.wifiStandard) {
    // If it's wifi Standard, update the wifi Extenders line item to be enabled
    yield put(
      actions.disableConfig(internetLineItemTypes.wifiExtenders, false)
    )
  }
  else {
    // If it's wifiPro or wifiPro2 or No wifi Selected, disable and reset the wifi Extenders line item
    yield put(
      actions.disableConfig(internetLineItemTypes.wifiExtenders, true)
    )
    yield put(
      actions.updateConfigItem({
        lineItem: wifiExtendersLineItem,
        item: { ...wifiExtendersItem, isSelected: false, quantity: 0 }
      })
    )
  }
  // Finally, update the config item with the provided lineItem and item
  yield put(
    actions.updateConfigItem({
      lineItem,
      item
    })
  )
}

export function* setInternetEquipmentSaga({ payload }) {
  const { item, lineItem } = payload
  const connectionProLineItem = yield select(
    selectLineItemByType(internetLineItemTypes.connectionPro)
  )
  const connectionProItem = getConfigItem(connectionProLineItem)
  const selectedTierSpeedLineItem = yield select(
    selectLineItemByType(internetLineItemTypes.selectedTierSpeed)
  )
  let selectedTierSpeedItem = getConfigItem(selectedTierSpeedLineItem)
  const state = yield select()
  const { uploadDownloadSpeed, downgradedGenesisSpeed = '' } = state.configuration.offer
  if (item.id === internetConfigurationItems.customerOwnedEquipment) {
    if (item.id === internetConfigurationItems.customerOwnedEquipment
      && downgradedGenesisSpeed) {
      selectedTierSpeedItem = {
        ...selectedTierSpeedItem,
        value: downgradedGenesisSpeed
      }
      yield put(
        actions.updateConfigItem({
          lineItem: selectedTierSpeedLineItem,
          item: selectedTierSpeedItem
        })
      )
    }
    // if there is no error and they chose customer own equipment, toggle the off the internet options
    yield put(actions.toggleInternetOptions(false))

    yield put(actions.updateConfigItem({ lineItem, item }))
    if (connectionProLineItem) {
      if (connectionProLineItem.hasAncillaryPrices) {
        const noSelectionItem =
          connectionProLineItem.configurationItems[
            internetConfigurationItems.noConnectionPro
          ]

        yield put(
          actions.configureItem(configTypes.internet, {
            lineItem: connectionProLineItem,
            item: { ...noSelectionItem, isSelected: true, quantity: 1 }
          })
        )
      } else {
        yield put(
          actions.configureItem(configTypes.internet, {
            lineItem: connectionProLineItem,
            item: { ...connectionProItem, isSelected: false, quantity: 0 }
          })
        )
      }
    }
  } else {
    const previousConfigItem = getSelectedConfigItem(lineItem)
    yield put(actions.updateConfigItem({ lineItem, item }))
    // toggle the internet options back on if pervious selection was "not customer owned equipment"
    if (
      previousConfigItem?.id ===
      internetConfigurationItems.customerOwnedEquipment
    ) {
      selectedTierSpeedItem = {
        ...selectedTierSpeedItem,
        value: uploadDownloadSpeed
      }
      yield put(
        actions.updateConfigItem({
          lineItem: selectedTierSpeedLineItem,
          item: selectedTierSpeedItem
        })
      )
      yield put(actions.toggleInternetOptions(true))
    }
  }
}

export function* toggleInternetOptions({ payload }) {
  const { enabled } = payload
  const connectionProLineItem = yield select(
    selectLineItemByType(internetLineItemTypes.connectionPro)
  )
  const connectionProItem = getConfigItem(connectionProLineItem)
  const wifiBasicLineItem = yield select(
    selectLineItemByType(internetLineItemTypes.wifi)
  )
  const wifiAPLineItem = yield select(
    selectLineItemByType(internetLineItemTypes.wifiAP)
  )
  const staticIpLineItem = yield select(
    selectLineItemByType(internetLineItemTypes.staticIps)
  )

  const securityEdgeLineItem = yield select(
    selectLineItemByType(internetLineItemTypes.securityEdge)
  )
  const securityEdgeItem = getConfigItem(securityEdgeLineItem)

  const wifiExtendersLineItem = yield select(
    selectLineItemByType(internetLineItemTypes.wifiExtenders)
  )
  const wifiExtendersItem = getConfigItem(wifiExtendersLineItem)

  // If it's customer owned equipment disable and reset the wifi Extenders line item to 0
  if (!!wifiExtendersLineItem && !!wifiExtendersItem) {
    yield put(
      actions.updateConfigItem({
        lineItem: wifiExtendersLineItem,
        item: { ...wifiExtendersItem, isSelected: false, quantity: 0 }
      })
    )
    yield put(
      actions.disableConfig(internetLineItemTypes.wifiExtenders, true)
    )
  }
  // When toggling, we always want the line items to be set to no selection
  // Then determine if it is enabled/disabled from the payload
  if (connectionProLineItem) {
    if (connectionProItem.id !== internetConfigurationItems.noConnectionPro) {
      yield put(
        actions.updateConfigItem({
          lineItem: connectionProLineItem,
          item: {
            ...connectionProItem,
            isSelected: false,
            quantity: 0
          }
        })
      )
    }
    yield put(
      actions.disableConfig(internetLineItemTypes.connectionPro, !enabled)
    )
  }

  if (securityEdgeLineItem) {
    const noSecurityEdgeItem =
      securityEdgeLineItem?.configurationItems[
        internetConfigurationItems.noSecurityEdge
      ]

    const securityEdgeExtCovLineItem = yield select(
      selectLineItemByType(internetLineItemTypes.securityEdgeExtCov)
    )
    const securityEdgeExtCovItem = getConfigItem(securityEdgeExtCovLineItem)

    // has ancillary prices and with no security edge selected in config items
    if (
      securityEdgeItem.id !== internetConfigurationItems.noSecurityEdge &&
      !noSecurityEdgeItem
    ) {
      yield put(
        actions.updateConfigItem({
          lineItem: securityEdgeLineItem,
          item: {
            ...securityEdgeItem,
            isSelected: false,
            quantity: 0
          }
        })
      )
    }

    // has ancillary prices and with no security edge selected in config items
    if (securityEdgeLineItem?.hasAncillaryPrices && noSecurityEdgeItem) {
      const noSelectionItem =
        securityEdgeLineItem.configurationItems[
          internetConfigurationItems.noSecurityEdge
        ]

      yield put(
        actions.updateConfigItem({
          lineItem: securityEdgeLineItem,
          item: { ...noSelectionItem, isSelected: true, quantity: 1 }
        })
      )
      yield put(
        actions.updateConfigItem({
          lineItem: securityEdgeExtCovLineItem,
          item: {
            ...securityEdgeExtCovItem,
            isSelected: false,
            quantity: 0
          }
        })
      )
    } else {
      // has no ancillary prices and SE checkbox
      if (securityEdgeExtCovItem?.isSelected) {
        yield put(
          actions.updateConfigItem({
            lineItem: securityEdgeExtCovLineItem,
            item: {
              ...securityEdgeExtCovItem,
              isSelected: false,
              quantity: 0
            }
          })
        )
      }
      yield put(
        actions.updateConfigItem({
          lineItem: securityEdgeLineItem,
          item: { ...securityEdgeItem, isSelected: false, quantity: 0 }
        })
      )
    }
    yield put(
      actions.disableConfig(internetLineItemTypes.securityEdge, !enabled)
    )
  }

  if (wifiAPLineItem?.configurationItems[internetConfigurationItems.noWifi]) {
    yield put(
      actions.updateConfigItem({
        lineItem: wifiAPLineItem,
        item: {
          ...wifiAPLineItem.configurationItems[
            internetConfigurationItems.noWifi
          ],
          isSelected: true,
          quantity: 1
        }
      })
    )
    yield put(actions.disableConfig(wifiAPLineItem.type, !enabled))
  }

  if (
    wifiBasicLineItem?.configurationItems[internetConfigurationItems.noWifi]
  ) {
    yield put(
      actions.updateConfigItem({
        lineItem: wifiBasicLineItem,
        item: {
          ...wifiBasicLineItem.configurationItems[
            internetConfigurationItems.noWifi
          ],
          isSelected: true,
          quantity: 1
        }
      })
    )
    yield put(actions.disableConfig(wifiBasicLineItem.type, !enabled))
  }

  yield put(
    actions.updateConfigItem({
      lineItem: staticIpLineItem,
      item: {
        ...staticIpLineItem.configurationItems[
          internetConfigurationItems.noStaticIp
        ],
        isSelected: true,
        quantity: 1
      }
    })
  )
  yield put(actions.disableConfig(internetLineItemTypes.staticIps, !enabled))
}

export function* updateStaticIPs({ payload }) {
  const { lineItem, item } = payload
  const { staticIpJustifications } = item.attributes
  const currentIpSelections = getSelectedConfigItem(lineItem)
  const ipCount = staticIpJustificationCounts[item.id]
  const currentIPs = currentIpSelections.attributes.staticIpJustifications || []
  let updatedJustifications = []

  // Create new array with copied values (ip count change)
  if (currentIPs.length !== ipCount) {
    updatedJustifications = new Array(ipCount > 1 ? ipCount : 0)
      .fill(defaultIPSelection)
      .map(
        (val, index) => currentIPs[index] || val // Map current value if set
      )
  } else {
    // Set new values (ip justification change)
    updatedJustifications = staticIpJustifications
  }

  yield put(
    actions.updateConfigItem({
      lineItem,
      item: {
        ...item,
        attributes: {
          ...item.attributes,
          staticIpJustifications: updatedJustifications
        }
      }
    })
  )
}

export function* updateConnectionProSaga({ payload }) {
  const { lineItem, item } = payload

  yield put(
    actions.updateConfigItem({
      lineItem,
      item
    })
  )
  const connectionProExtBatLineItem = yield select(
    selectLineItemByType(internetLineItemTypes.connectionProExtBat)
  )
  const connectionProExtBatItem = getConfigItem(connectionProExtBatLineItem)

  // unset battery if no selection or unchecked box
  if (
    (item.id === internetConfigurationItems.noConnectionPro ||
      !item.isSelected) &&
    connectionProExtBatLineItem
  ) {
    yield put(
      actions.updateConfigItem({
        lineItem: connectionProExtBatLineItem,
        item: {
          ...connectionProExtBatItem,
          isSelected: false,
          quantity: 0
        }
      })
    )
  }
}

export function* updateSecurityEdgeSaga({ payload }) {
  const { lineItem, item } = payload

  yield put(
    actions.updateConfigItem({
      lineItem,
      item
    })
  )
  const securityEdgeExtCovLineItem = yield select(
    selectLineItemByType(internetLineItemTypes.securityEdgeExtCov)
  )
  const securityEdgeExtCovItem = getConfigItem(securityEdgeExtCovLineItem)

  // unset battery if no selection or unchecked box
  if (
    (item.id === internetConfigurationItems.noSecurityEdge ||
      !item.isSelected) &&
    securityEdgeExtCovLineItem
  ) {
    yield put(
      actions.updateConfigItem({
        lineItem: securityEdgeExtCovLineItem,
        item: {
          ...securityEdgeExtCovItem,
          isSelected: false,
          quantity: 0
        }
      })
    )
  }
}

function* rootSaga() {
  yield takeLatest(
    actions.CONFIG_ITEM_INTERNET,
    configurationLineItemSaga(lineItemSagas)
  )

  yield takeLatest(actions.TOGGLE_INTERNET_OPTIONS, toggleInternetOptions)
}

export default rootSaga
